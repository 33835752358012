<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.examOutput.menu"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.examOutput.list.title"
        ></app-i18n>
      </h1>

      <app-exam-output-list-toolbar></app-exam-output-list-toolbar>
      <app-exam-output-list-filter></app-exam-output-list-filter>
      <app-exam-output-list-table></app-exam-output-list-table>
    </div>
  </div>
</template>

<script>
import ExamOutputListFilter from '@/modules/exam-output/components/exam-output-list-filter.vue';
import ExamOutputListTable from '@/modules/exam-output/components/exam-output-list-table.vue';
import ExamOutputListToolbar from '@/modules/exam-output/components/exam-output-list-toolbar.vue';

export default {
  name: 'app-exam-output-list-page',

  components: {
    [ExamOutputListFilter.name]: ExamOutputListFilter,
    [ExamOutputListTable.name]: ExamOutputListTable,
    [ExamOutputListToolbar.name]: ExamOutputListToolbar,
  },
};
</script>

<style></style>
