<template>
  <div>
    <el-table stripe
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column align="center" type="selection" width="55"></el-table-column>

      <!--      <el-table-column :label="fields.id.label" :prop="fields.id.name" sortable="custom">-->
      <!--        <template slot-scope="scope">{{ presenter(scope.row, 'id') }}</template>-->
      <!--      </el-table-column>-->

      <el-table-column align="center" :label="fields.student.label" :prop="fields.student.name">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p>{{ getEmail(scope) }}</p>
            <div slot="reference" class="name-wrapper">
              <app-list-item-relation-to-one
                      :label="fields.student.label"
                      :permission="fields.student.readPermission"
                      :url="fields.student.viewUrl"
                      :value="presenter(scope.row, 'student')"
              ></app-list-item-relation-to-one>
            </div>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column align="center" :label="fields.exam.label" :prop="fields.exam.name">
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.exam.label"
            :permission="fields.exam.readPermission"
            :url="fields.exam.viewUrl"
            :value="presenter(scope.row, 'exam')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column align="center"
        :label="fields.questionNumber.label"
        :prop="fields.questionNumber.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{ presenter(scope.row, 'questionNumber') }}</template>
      </el-table-column>

      <el-table-column align="center"
              :label="fields.outputStatus.label"
              :prop="fields.outputStatus.name"
              sortable="custom"
      >
        <template slot-scope="scope">
          {{ presenter(scope.row, 'outputStatus') }}
        </template>
      </el-table-column>


      <el-table-column align="center" :label="fields.question.label" :prop="fields.question.name">
        <template slot-scope="scope">
          <app-list-item-relation-to-one
            :label="fields.question.label"
            :permission="fields.question.readPermission"
            :url="fields.question.viewUrl"
            :value="presenter(scope.row, 'question')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <!--      <el-table-column :label="fields.answer.label" :prop="fields.answer.name" sortable="custom">-->
      <!--        <template slot-scope="scope">{{ presenter(scope.row, 'answer') }}</template>-->
      <!--      </el-table-column>-->

      <el-table-column
        :label="fields.timeSpent.label"
        :prop="fields.timeSpent.name"
        sortable="custom"
      >
        <template slot-scope="scope">{{
          presenter(scope.row, 'timeSpent')
        }}</template>
      </el-table-column>

      <!--      <el-table-column-->
      <!--        :label="fields.violationCount.label"-->
      <!--        :prop="fields.violationCount.name"-->
      <!--        sortable="custom"-->
      <!--      >-->
      <!--        <template slot-scope="scope">{{ presenter(scope.row, 'violationCount') }}</template>-->
      <!--      </el-table-column>-->

      <el-table-column align="center" :fixed="isMobile ? undefined : 'right'" width="180">
        <template slot-scope="scope">
          <div class="table-actions">
            <router-link :to="`/exam-output/${scope.row.id}`">
              <el-button type="text">
                <app-i18n code="common.view"></app-i18n>
              </el-button>
            </router-link>

            <!--            <router-link :to="`/exam-output/${scope.row.id}/edit`" v-if="hasPermissionToEdit">-->
            <!--              <el-button type="text">-->
            <!--                <app-i18n code="common.edit"></app-i18n>-->
            <!--              </el-button>-->
            <!--            </router-link>-->

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { ExamOutputModel } from '@/modules/exam-output/exam-output-model';
import { mapGetters, mapActions } from 'vuex';
import { ExamOutputPermissions } from '@/modules/exam-output/exam-output-permissions';
import { i18n } from '@/i18n';

const { fields } = ExamOutputModel;

export default {
  name: 'app-exam-output-list-table',

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'examOutput/list/rows',
      count: 'examOutput/list/count',
      loading: 'examOutput/list/loading',
      pagination: 'examOutput/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'examOutput/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new ExamOutputPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ExamOutputPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'examOutput/list/doChangeSort',
      doChangePaginationCurrentPage:
        'examOutput/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'examOutput/list/doChangePaginationPageSize',
      doMountTable: 'examOutput/list/doMountTable',
      doDestroy: 'examOutput/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return ExamOutputModel.presenter(row, fieldName);
    },

    getEmail(scope){
      if(scope.row.student)
        return scope.row.student.email
      else
        return "-"
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
